import { useSnackbar } from 'material-ui-snackbar-provider'
import { useEffect } from 'react'
import { useApolloNetworkStatus } from '../config/apollo'

function errorsToString(errors) {
	return errors
		.map(({ extensions: { code }, message }) => `[${code}]: ${message}`)
		.join(', ')
}

function errorToString(error) {
	return errorsToString(
		error.networkError?.result?.errors ||
			error?.graphQLErrors || [
				{
					extensions: {
						code: 'TypeError'
					},
					message: error.networkError?.message
				}
			]
	)
}

const ApolloNetworkStatusIndicator = ({ children }) => {
	const status = useApolloNetworkStatus()
	const snackbar = useSnackbar()

	useEffect(() => {
		if (status.mutationError) {
			console.error({ mutationError: status.mutationError })
			snackbar.showMessage(errorToString(status.mutationError))
		}

		if (status.queryError) {
			console.error({ queryError: status.queryError })
			snackbar.showMessage(errorToString(status.queryError))
		}
	}, [status, snackbar])

	return null
}

export default ApolloNetworkStatusIndicator
